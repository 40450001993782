import "./App.css";
import Hero from "./components/Hero/Hero";
import Programs from "./components/Programs/Programs";
import Reasons from "./components/Reasons/Reasons";
import Plans from "./components/Plans/Plan";
import Feedbacks from "./components/Feedback/Feedback";
import Join from "./components/Join/Join";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      {/* <span style={{color: 'white'}}>By Askeeb</span> */}
      <Hero />
      <Programs />
      <Reasons />
      <Plans />
      <Feedbacks />
      <Join />
      <div class="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1677.084840086365!2d13.144666922083564!3d32.78775989242007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13a8eb0d1c76060b%3A0xa2b7bad22f27624e!2sFloridaGym%20LIBYA!5e0!3m2!1sen!2sly!4v1676893146365!5m2!1sen!2sly"
          width="1024"
          height="450"
          style={{ border: "0", margin: "auto" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <Footer />
    </div>
  );
}

export default App;
